@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Source+Sans+Pro&family=League+Gothic&display=swap');

.notification-success .ant-message-notice-content {
  background-color: #92c94e;
  width: 500px;
  text-align: left;
  border-radius: 3px;
  color: #333333;
  font-size: 14px;
  padding: 14px;
}

.notification-error .ant-message-notice-content {
  background-color: #d51e00;
  width: 500px;
  color: white !important;
  text-align: left;
  border-radius: 3px;
  font-size: 14px;
  padding: 14px;

  .ant-notification-notice-message {
    color: white !important;
  }
}

.notification-icon {
  margin-right: 15px;
}

.notification-discover-warning {
  background: #474747 !important;
  width: max-content !important;
  padding: 8px !important;
  .ant-notification-notice-icon {
    font-size: 16px;
    margin-left: 0;
    svg {
      fill: #ff8700;
    }
  }
  .ant-notification-notice-message {
    margin-bottom: 0 !important;
    margin-left: 20px !important;
    color: white;
  }
  .ant-notification-notice-close {
    top: 8px;
    right: 8px;
  }
}

.discover-notification-container {
  .ant-notification {
    position: absolute !important;
    z-index: 1 !important;
  }
}

// For some reason the ant-dropdown appears over the modal without this
.ant-dropdown {
  z-index: 950 !important;
}

.discover-node-label {
  font-size: 10px;
  color: #1a1a1a;
}

.discover-graph-mode {
  border: 0;

  label.ant-radio-button-wrapper {
    border: 0;
    background-color: #1a1a1a;
  }

  label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    border: 0;
    background-color: #38b1cc !important;
  }
}

.highcharts-tooltip-container {
  z-index: 1000 !important;
}

.slider-tooltip-container {
  .slider-tooltip {
    width: 400px;
    height: 100%;
    padding: 2px 15px;
    background: #2e2e2e;
    color: white;
    span {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 8px;
    }
    .slider-benchmark {
      font-size: 12px;
    }
    .slider-heading {
      font-size: 16px;
    }
    .slider-label {
      margin-right: 6px;
      font-weight: normal;
      max-width: 150px;
    }
    .slider-heading-score {
      font-weight: 700;
      margin-right: 3px;
    }
  }
}

.auto-complete-dropdown {
  padding: 5px 0 !important;
  background-color: #191c21 !important;
  border: 1px solid #20242d !important;
  .ant-select-item-option-content {
    color: #9c9c9c;
    .search-hit {
      color: #9b9b9b;
      font-weight: 600;
    }
  }
  .ant-select-item-option-active {
    background-color: #313744 !important;
    .ant-select-item-option-content {
      color: #f3f3f3;
      .search-hit {
        color: #fff;
        font-weight: 600;
      }
    }
  }
}

.tooltip-header {
  margin-bottom: 6px;
}
.tooltip-icons {
  display: flex;
  .tooltip-icon {
    display: flex;
    margin-right: 16px;
    &:last-child {
      margin-right: 0px;
    }
    div {
      width: 16px;
      height: 16px;
      border-radius: 16px;
      margin-right: 4px;
    }
    span {
      width: max-content;
    }
  }
}

.drawer-action-menu {
  background-color: #141414 !important;
  li {
    color: white !important;
    &:hover {
      background-color: #939393;
    }
  }
}

thead {
  user-select: none;
}

.score-list-card {
  .ant-collapse::-webkit-scrollbar {
    width: 5px;
  }
  .ant-collapse::-webkit-scrollbar-track {
    background: rgb(20, 20, 20);
  }
  .ant-collapse::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: white !important;
  }
}

.ant-table-container {
  td {
    word-break: break-all;
  }
}

.dc-chart path.dc-symbol,
.dc-legend g.dc-legend-item.fadeout {
  fill-opacity: 0.5;
  stroke-opacity: 0.5;
}

div.dc-chart {
  float: left;
}

.dc-chart rect.bar {
  stroke: none;
  cursor: pointer;
}
.dc-chart rect.bar:hover {
  fill-opacity: 0.5;
}

.dc-chart rect.deselected {
  stroke: none;
  fill: #ccc;
}

.dc-chart .pie-slice {
  fill: #fff;
  font-size: 12px;
  cursor: pointer;
}
.dc-chart .pie-slice.external {
  fill: #000;
}
.dc-chart .pie-slice :hover,
.dc-chart .pie-slice.highlight {
  fill-opacity: 0.8;
}

.dc-chart .pie-path {
  fill: none;
  stroke-width: 2px;
  stroke: #000;
  opacity: 0.4;
}

.dc-chart .selected path,
.dc-chart .selected circle {
  stroke-width: 3;
  stroke: #ccc;
  fill-opacity: 1;
}

.dc-chart .deselected path,
.dc-chart .deselected circle {
  stroke: none;
  fill-opacity: 0.5;
  fill: #ccc;
}

.dc-chart .axis path,
.dc-chart .axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.dc-chart .axis text {
  font: 10px sans-serif;
}

.dc-chart .grid-line,
.dc-chart .axis .grid-line,
.dc-chart .grid-line line,
.dc-chart .axis .grid-line line {
  fill: none;
  stroke: #ccc;
  opacity: 0.5;
  shape-rendering: crispEdges;
}

.dc-chart .brush rect.background {
  z-index: -999;
}

.dc-chart .brush rect.extent {
  fill: #4682b4;
  fill-opacity: 0.125;
}

.dc-chart .brush .resize path {
  fill: #eee;
  stroke: #666;
}

.dc-chart path.line {
  fill: none;
  stroke-width: 1.5px;
}

.dc-chart path.area {
  fill-opacity: 0.3;
  stroke: none;
}

.dc-chart path.highlight {
  stroke-width: 3;
  fill-opacity: 1;
  stroke-opacity: 1;
}

.dc-chart g.state {
  cursor: pointer;
}
.dc-chart g.state :hover {
  fill-opacity: 0.8;
}
.dc-chart g.state path {
  stroke: #fff;
}

.dc-chart g.deselected path {
  fill: #808080;
}

.dc-chart g.deselected text {
  display: none;
}

.dc-chart g.row rect {
  fill-opacity: 0.8;
  cursor: pointer;
}
.dc-chart g.row rect:hover {
  fill-opacity: 0.6;
}

.dc-chart g.row text {
  fill: #fff;
  font-size: 12px;
  cursor: pointer;
}

.dc-chart g.dc-tooltip path {
  fill: none;
  stroke: #808080;
  stroke-opacity: 0.8;
}

.dc-chart g.county path {
  stroke: #fff;
  fill: none;
}

.dc-chart g.debug rect {
  fill: #00f;
  fill-opacity: 0.2;
}

.dc-chart g.axis text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.dc-chart .node {
  font-size: 0.7em;
  cursor: pointer;
}
.dc-chart .node :hover {
  fill-opacity: 0.8;
}

.dc-chart .bubble {
  stroke: none;
  fill-opacity: 0.6;
}

.dc-chart .highlight {
  fill-opacity: 1;
  stroke-opacity: 1;
}

.dc-chart .fadeout {
  fill-opacity: 0.2;
  stroke-opacity: 0.2;
}

.dc-chart .box text {
  font: 10px sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.dc-chart .box line,
.dc-chart .box circle {
  fill: #fff;
}

.dc-chart .box rect,
.dc-chart .box line,
.dc-chart .box circle {
  stroke: #000;
  stroke-width: 1.5px;
}

.dc-chart .box .center {
  stroke-dasharray: 3, 3;
}

.dc-chart .box .outlier {
  fill: none;
  stroke: #ccc;
}

.dc-chart .box.deselected {
  opacity: 0.5;
}
.dc-chart .box.deselected .box {
  fill: #ccc;
}

.dc-chart .symbol {
  stroke: none;
}

.dc-chart .heatmap .box-group.deselected rect {
  stroke: none;
  fill-opacity: 0.5;
  fill: #ccc;
}

.dc-chart .heatmap g.axis text {
  pointer-events: all;
  cursor: pointer;
}

.dc-chart .empty-chart .pie-slice {
  cursor: default;
}
.dc-chart .empty-chart .pie-slice path {
  fill: #fee;
  cursor: default;
}

.dc-chart circle.dot {
  stroke: none;
}

.dc-data-count {
  float: right;
  margin-top: 15px;
  margin-right: 15px;
}
.dc-data-count .filter-count,
.dc-data-count .total-count {
  color: #3182bd;
  font-weight: bold;
}

.dc-legend {
  font-size: 11px;
}
.dc-legend .dc-legend-item {
  cursor: pointer;
}

.dc-hard .number-display {
  float: none;
}

/* Override dc styles */
.dc-chart {
  float: inherit !important;
}
.dc-chart .axis path,
.axis line,
.grid-line,
.grid-line line {
  stroke: #404040;
}

.dc-chart .axis text {
  font-size: 14px;
  fill: white;
}

.dc-chart#assets-daily-chart .axis text {
  fill: black;
}

.dc-chart .x text {
  font-size: 12px;
  fill: #808080;
}

#main-chart .y text {
  fill: #3da1b3;
}

#temp-chart {
  margin-top: 15px;
  text-align: center;
}

#temp-chart .y text {
  fill: #3da1b3;
  font-size: 12px;
}

#temp-chart .x text {
  fill: #f5ebdc;
  font-size: 10px;
}

#secondary-chart .y text {
  fill: #0367a8;
}

#main-chart .yr text {
  fill: #3e7496;
}

.dc-chart select {
  color: #000;
}

#main-chart path.area {
  fill-opacity: 0.5;
}

.dc-chart#composite-chart,
.dc-chart#precip-chart {
  position: relative;
  z-index: 1;
}

.d3-tip {
  font-family: sourcesanspro-regular, 'Helvetica Neue', Helvetica, sans-serif;
  size: 14px;
  font-weight: 400;
  padding: 12px;
  opacity: 0.6;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 2px;
  border: 1px solid #666d87;
  pointer-events: none;
  z-index: 9999;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  position: absolute;
  pointer-events: none;
  z-index: 1000;
}

/* Northward tooltips */
.d3-tip.n:after {
  content: '\\25BC';
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
  text-align: center;
}

/* Eastward tooltips */
.d3-tip.e:after {
  content: '\\25C0';
  margin: -4px 0 0 0;
  top: 50%;
  left: -8px;
}

/* Southward tooltips */
.d3-tip.s:after {
  content: '\\25B2';
  margin: 0 0 1px 0;
  top: -8px;
  left: 0;
  text-align: center;
}

/* Westward tooltips */
.d3-tip.w:after {
  content: '\\25B6';
  margin: -4px 0 0 -1px;
  top: 50%;
  left: 100%;
}

.chart-tip {
  text-align: center;
}

.chart-tip-top {
  color: #3da1b3;
}

.risk-tip-threats {
  font-size: 13px;
}

.ant-dropdown-menu {
  background-color: #141414 !important;

  .ant-dropdown-menu-item {
    color: rgba(255, 255, 255, 0.75);

    &:hover {
      background-color: #ffffff60 !important;
      color: #fff;
    }
  }
}

.full-width-input {
  input {
    width: 100%;
  }
}

.custom-option {
  position: relative;
  label {
    position: absolute;
    display: inline-block;
    right: 8px;
    top: 8px;
    height: calc(100% - 16px);
    padding: 0px 6px;
    border-radius: 4px;
    background: #474747;
  }
}
