@media screen and (max-width: 1440px) {
  #edit_risk_profile .ant-form-item {
    flex-direction: column;
  }
  #edit_risk_profile .ant-form-item .ant-col.ant-form-item-label {
    text-align: left;
  }
}

@media screen and (max-width: 1240px) {
    #edit_risk_profile {
        flex-direction: column;
      }
}

